import React from 'react';
import { Helmet } from 'react-helmet';
import Cookies from 'universal-cookie';
import slugify from 'slugify';

import NavigationBar from '../NavigationBar';
import IEWarning from '../IEWarning';
import { createPreviewPath } from '../../helper';
import getSnippets from '../../helpers/getSnippets';
import getTag from '../../helpers/getTag';
import getUsedFonts from '../../helpers/getUsedFonts';

const cookies = new Cookies();

class NavHead extends React.Component {
  /* eslint-disable default-case */

  static getFontSize(style, size) {
    if (style === 'h1') {
      switch (size) {
        case 'Small':
          return '3.4rem';
        case 'Normal':
          return '4.0rem';
        case 'Large':
          return '5.0rem';
      }
    }

    if (style === 'h2') {
      switch (size) {
        case 'Small':
          return '2.55rem';
        case 'Normal':
          return '3.0rem';
        case 'Large':
          return '3.75rem';
      }
    }

    if (style === 'h3') {
      switch (size) {
        case 'Small':
          return '1.7rem';
        case 'Normal':
          return '2.0rem';
        case 'Large':
          return '2.5rem';
      }
    }

    if (style === 'h4') {
      switch (size) {
        case 'Small':
          return '1.275rem';
        case 'Normal':
          return '1.5rem';
        case 'Large':
          return '1.875rem';
      }
    }

    if (style === 'h5') {
      switch (size) {
        case 'Small':
          return '1.19rem';
        case 'Normal':
          return '1.4rem';
        case 'Large':
          return '1.75rem';
      }
    }

    if (style === 'p' || style === 'h6') {
      switch (size) {
        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';
      }
    }

    if (style === 'button') {
      switch (size) {
        case 'Small':
          return '0.85rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.25rem';
      }
    }

    return '1.0rem';
  }

  static getFontSizeAlt(style, size) {
    if (style === 'h1') {
      switch (size) {
        case 'Small':
          return '1.75rem';
        case 'Normal':
          return '2rem';
        case 'Large':
          return '2.125rem';
      }
    }

    if (style === 'h2') {
      switch (size) {
        case 'Small':
          return '1.625rem';
        case 'Normal':
          return '1.875rem';
        case 'Large':
          return '2rem';
      }
    }

    if (style === 'h3') {
      switch (size) {
        case 'Small':
          return '1.375rem';
        case 'Normal':
          return '1.5rem';
        case 'Large':
          return '1.625rem';
      }
    }

    if (style === 'h4') {
      switch (size) {
        case 'Small':
          return '1.25rem';
        case 'Normal':
          return '1.375rem';
        case 'Large':
          return '1.5rem';
      }
    }

    if (style === 'h5') {
      switch (size) {
        case 'Small':
          return '1.125rem';
        case 'Normal':
          return '1.25rem';
        case 'Large':
          return '1.375rem';
      }
    }

    if (style === 'h6') {
      switch (size) {
        case 'Small':
          return '0.875rem';
        case 'Normal':
          return '1.0rem';
        case 'Large':
          return '1.125rem';
      }
    }

    return '1.0rem';
  }
  /* eslint-enable default-case */

  constructor(props) {
    super(props);

    cookies.get('cookiesAccepted');

    const { necessaryHead, necessaryStart, necessaryBefore, head, before, start } = getSnippets(
      props.pageContext.seo.trackers,
      props.pageContext.page._id,
      cookies?.cookies?.cookiesAccepted === 'true',
    );

    let previewImage;
    if (props.previewSrc) previewImage = props.previewSrc;
    else if (
      props.pageContext.page.previewImage !== null &&
      props.pageContext.page.previewImage !== undefined &&
      props.pageContext.page.previewImage !== ''
    ) {
      previewImage = props.pageContext.page.previewImage;
    } else if (
      props.pageContext.seo.defaultPreview !== undefined &&
      props.pageContext.seo.defaultPreview !== null &&
      props.pageContext.seo.defaultPreview !== ''
    ) {
      previewImage = props.pageContext.seo.defaultPreview;
    }

    if (!props.previewSrc && previewImage !== undefined) {
      props.pageContext.images.some((image) => {
        let found = false;
        if (image.path === previewImage || image._id.toString() === previewImage) {
          found = true;
          previewImage = createPreviewPath(image);
        }

        return found;
      });
    }

    const siteTitle = props.pageContext.seo.siteNameInTitle
      ? props.pageContext.seo.siteName || props.pageContext.sitename
      : undefined;

    const pageTitle =
      props.metaTitle || props.title || props.pageContext.page.metaTitle || props.pageContext.page.title;

    let description;
    if (props.metaDescription) description = props.metaDescription;
    else if (props.desc) description = props.desc;
    else if (props.pageContext.page && props.pageContext.page.metaDescription) {
      description = props.pageContext.page.metaDescription;
    }

    let commType = 'admins';
    if (props.comments && props.comments.commType === 'app') {
      commType = 'app_id';
    }

    let faceId;
    if (commType && props.comments && props.comments.faceId) {
      faceId = props.comments.faceId;
      if (typeof document !== 'undefined' && !document.getElementById('fb-root')) {
        const lang = props.pageContext.metaLanguage === 'fi' ? 'fi_FI' : 'en_US';
        const script = document.createElement('script');
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'anonymous';
        script.src = `https://connect.facebook.net/${lang}/sdk.js#xfbml=1&version=v9.0${
          commType === 'app_id' ? `&appId=${faceId}` : ''
        }`;
        necessaryStart.push(script);

        const div = document.createElement('div');
        div.id = 'fb-root';
        necessaryStart.push(div);
      }
    }

    this.state = {
      necessaryStart,
      necessaryHead,
      necessaryBefore,
      head,
      before,
      title: [pageTitle, siteTitle].filter(Boolean).join(' | '),
      metaTitle: pageTitle,
      previewImage,
      description,
      start,
      faceId,
      commType,
    };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const fonts = getUsedFonts(
        this.props.pageContext.themeData.typography,
        this.props.pageContext.nameActive,
        this.props.pageContext.logo,
      );

      Object.entries(fonts).forEach(([family, weights]) => {
        weights.forEach((weight) => {
          import(
            // use the commons-chunk and try to limit unnecessary files. this still leaves in wrong font families
            /* webpackInclude: /(300|400|700|900)\.css$/, webpackMode: "eager" */
            `@fontsource/${slugify(family, {
              lower: true,
              strict: true,
            })}/${weight}.css`
          ).catch();
        });
      });
    }

    this.state.necessaryBefore.forEach((b) => {
      const tag = getTag(b, b.nodeName?.toLowerCase());
      document.body.appendChild(tag);
    });

    this.state.necessaryStart.forEach((b) => {
      const tag = getTag(b, b.nodeName?.toLowerCase());
      document.body.prepend(tag);
    });

    if (cookies?.cookies?.cookiesAccepted === 'true') {
      this.state.head.forEach((b) => {
        const tag = getTag(b, b.nodeName?.toLowerCase());
        document.head.appendChild(tag);
      });

      this.state.before.forEach((b) => {
        const tag = getTag(b, b.nodeName?.toLowerCase());
        document.body.appendChild(tag);
      });

      this.state.start.forEach((b) => {
        const tag = getTag(b, b.nodeName?.toLowerCase());
        document.body.prepend(tag);
      });
    }
  }

  render() {
    const hasInstagram = this.props.pageContext.page.sections.some(
      (section) => (section.type === 'SOCIAL' && section.data[4].active) || section.type === 'INSTAGRAM',
    );
    const hasFacebook = this.props.pageContext.page.sections.some(
      (section) => section.type === 'SOCIAL' && section.data[2].active,
    );
    const hasTwitter = this.props.pageContext.page.sections.some(
      (section) => section.type === 'SOCIAL' && section.data[3].active,
    );
    const { themeData } = this.props.pageContext;
    const themeHeadingStyle = {
      fontFamily: themeData.typography.heading.name,
      weight: themeData.typography.heading.weight,
      lineHeight: themeData.typography.heading.lineHeight,
      letterSpacing: themeData.typography.heading.letterSpacing,
      textTransform: themeData.typography.heading.textTransform,
    };

    const themeDefaultStyle = {
      fontFamily: themeData.typography.default.name,
      weight: themeData.typography.default.weight,
      lineHeight: themeData.typography.default.lineHeight,
      letterSpacing: themeData.typography.default.letterSpacing,
    };

    const themeNavigationStyle = {
      fontFamily: themeData.typography.navigation.name,
      weight: themeData.typography.navigation.weight,
      lineHeight: themeData.typography.navigation.lineHeight,
      letterSpacing: themeData.typography.navigation.letterSpacing,
      textTransform: themeData.typography.navigation.textTransform,
    };

    const themeButtonStyle = {
      fontFamily: themeData.typography.button.name,
      weight: themeData.typography.button.weight,
      lineHeight: themeData.typography.button.lineHeight,
      letterSpacing: themeData.typography.button.letterSpacing,
      textTransform: themeData.typography.button.textTransform,
    };

    const themeFooterStyle = {
      fontFamily: themeData.typography.footer.name,
      weight: themeData.typography.footer.weight,
      lineHeight: themeData.typography.footer.lineHeight,
      letterSpacing: themeData.typography.footer.letterSpacing,
    };

    const color0 = { color: themeData.colors[0] };
    const color1 = { color: themeData.colors[1] };
    const color3 = { color: themeData.colors[3] };

    const c = themeData.link?.color || themeData?.colors[0];
    const w = themeData.link?.weight || themeData.typography.default.weight;
    let d = ['none', 'underline'].includes(themeData.link?.decoration ?? 'none')
      ? `text-decoration: ${themeData.link?.decoration ?? 'none'};`
      : `text-decoration: underline ${themeData?.link?.decoration};`;
    if (themeData.link?.decoration && themeData.link?.decoration !== 'none') {
      d = `${d} text-underline-offset: 2px;`;
    }

    const css = `
      strong { font-weight: bold; }
      body, input, textarea { margin: 0; font-weight: ${themeDefaultStyle.weight}; font-family: ${
      themeDefaultStyle.fontFamily
    }, Source Sans Pro, sans-serif; }
      h1  { margin: 0; font-size:${NavHead.getFontSize('h1', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h2 { margin: 0; font-size:${NavHead.getFontSize('h2', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h3 { margin: 0; font-size:${NavHead.getFontSize('h3', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h4 { margin: 0; font-size:${NavHead.getFontSize('h4', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h5 { margin: 0; font-size:${NavHead.getFontSize('h5', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      h6 { margin: 0; font-size:${NavHead.getFontSize('h6', themeData.typography.heading.fontSize)}; text-transform:${
      themeData.typography.heading.textTransform
    }; color: ${color3.color}; font-family: ${themeHeadingStyle.fontFamily}; font-weight: ${
      themeHeadingStyle.weight
    }; line-height: ${themeHeadingStyle.lineHeight}; letter-spacing: ${themeHeadingStyle.letterSpacing}; }
      @media only screen and (max-width: 767px) {
        h1 { font-size: ${NavHead.getFontSizeAlt('h1', themeData.typography.heading.fontSize)}; }
        h2 { font-size: ${NavHead.getFontSizeAlt('h2', themeData.typography.heading.fontSize)}; }
        h3 { font-size: ${NavHead.getFontSizeAlt('h3', themeData.typography.heading.fontSize)}; }
        h4 { font-size: ${NavHead.getFontSizeAlt('h4', themeData.typography.heading.fontSize)}; }
        h5 { font-size: ${NavHead.getFontSizeAlt('h5', themeData.typography.heading.fontSize)}; }
        h6 { font-size: ${NavHead.getFontSizeAlt('h6', themeData.typography.heading.fontSize)}; }
      }
      h3.footerHeading { font-size:${NavHead.getFontSize('h3', themeData.typography.footer.fontSize)}; text-transform:${
      themeData.typography.footer.textTransform
    }; font-family: ${themeFooterStyle.fontFamily}; font-weight: ${themeFooterStyle.weight}; line-height: ${
      themeFooterStyle.lineHeight
    }; letter-spacing: ${themeFooterStyle.letterSpacing}; }
      .storyFull h1, .storyFull h2, .storyFull h3, .storyFull h4, .storyFull p { color: ${color1.color}; }
      ul { line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .NavLink a, .NavLink ul a li {font-size:${NavHead.getFontSize(
        'p',
        themeData.typography.navigation.fontSize,
      )}; color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; text-transform:${
      themeData.typography.navigation.textTransform
    }; font-weight: ${themeNavigationStyle.weight}; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${
      themeNavigationStyle.letterSpacing
    }; }
      a { color: ${color0.color}; font-family: ${themeNavigationStyle.fontFamily}; font-weight: ${
      themeNavigationStyle.weight
    }; line-height: ${themeNavigationStyle.lineHeight}; letter-spacing: ${
      themeNavigationStyle.letterSpacing
    }; -webkit-user-drag: none; }
      button, .BUTTON { font-size:${NavHead.getFontSize(
        'button',
        themeData.typography.button.fontSize,
      )}; text-transform:${themeData.typography.button.textTransform}; font-family: ${
      themeButtonStyle.fontFamily
    }; font-weight: ${themeButtonStyle.weight}; line-height: ${themeButtonStyle.lineHeight}; letter-spacing: ${
      themeButtonStyle.letterSpacing
    }; }
      p, .infoRow {font-size:${NavHead.getFontSize('p', themeData.typography.default.fontSize)}; text-transform:${
      themeData.typography.default.textTransform
    }; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${themeDefaultStyle.weight}; line-height: ${
      themeDefaultStyle.lineHeight
    }; letter-spacing: ${themeDefaultStyle.letterSpacing};  margin-block-start: unset; margin-block-end: unset; }
      p a, p a:visited, .defaultTextLink a, .defaultTextLink a:visited {color: ${c}; font-size:${NavHead.getFontSize(
      'p',
      themeData.typography.default.fontSize,
    )}; text-transform:${themeData.typography.default.textTransform}; font-family: ${
      themeDefaultStyle.fontFamily
    }; font-weight: ${w}; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${
      themeDefaultStyle.letterSpacing
    }; ${d} margin-block-start: unset; margin-block-end: unset; }
      .inputLabel p, .inputLabel p span, .inputLabel span { font-size: ${NavHead.getFontSize(
        'p',
        themeData.typography.default.fontSize,
      )}; color: ${color3.color}; font-family: ${themeDefaultStyle.fontFamily}; font-weight: ${
      themeDefaultStyle.weight
    }; line-height: ${themeDefaultStyle.lineHeight}; letter-spacing: ${themeDefaultStyle.letterSpacing}; }
      .grecaptcha-badge { visibility: hidden; }
    .longarticle h1, .longarticle h1 *, .article h1, .article h1 *, h1.t, h1.t * { font-size:${NavHead.getFontSizeAlt(
      'h1',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h2, .longarticle h2 *, .article h2, .article h2 *, h2.t, h2.t * { font-size:${NavHead.getFontSizeAlt(
      'h2',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h3, .longarticle h3 *, .article h3, .article h3 *, h3.t, h3.t * { font-size:${NavHead.getFontSizeAlt(
      'h3',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h4, .longarticle h4 *, .article h4, .article h4 *, h4.t, h4.t * { font-size:${NavHead.getFontSizeAlt(
      'h4',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h5, .longarticle h5 *, .article h5, .article h5 *, h5.t, h5.t * { font-size:${NavHead.getFontSizeAlt(
      'h5',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h6, .longarticle h6 *, .article h6, .article h6 *, h6.t, h6.t * { font-size:${NavHead.getFontSizeAlt(
      'h6',
      themeData.typography.heading.fontSize,
    )}; }
    .longarticle h1, .longarticle h1 *, .longarticle h2, .longarticle h2 *, .longarticle h3, .longarticle h3 *, .longarticle h4, .longarticle h4 *, .longarticle h5, .longarticle h5 *, .longarticle h6, .longarticle h6 *, .article h1, .article h1 *, .article h2, .article h2 *, .article h3, .article h3 *, .article h4, .article h4 *, .article h5, .article h5 *, .article h6, .article h6 *, h1.t, h1.t *, h2.t, h2.t *, h3.t, h3.t *, h4.t, h4.t *, h5.t, h5.t *, h6.t, h6.t * { line-height: ${
      themeHeadingStyle.lineHeight
    }; letter-spacing: ${themeHeadingStyle.letterSpacing}; font-family: ${
      themeHeadingStyle.fontFamily
    }; text-transform: initial; }
    h1.t { margin: 0 0 0.75rem 0; }
    h2.t, h3.t { margin: 1rem 0 0.75rem 0; }
    h4.t, h5.t, h6.t { margin: 0.75rem 0 0.5rem 0; }
    h1 a, h1.t a, h2.t a, h2 a, h3.t a, h3 a, h4.t a, h4 a, h5.t a, h5 a, h6.t a, h6 a { color: ${c}; font-weight: ${w}; ${d} }
    h1.t, h2.t, h3.t, h4.t, h5.t, h6.t, .longarticle h1, .longarticle h2, .longarticle h3, .longarticle h4, .longarticle h5, .longarticle h6 { color: #000; font-weight: inherit; }
    .article h1, .article h2, .article h3, .article h4, .article h5, .article h6 { color: #000; font-weight: initial; }
    `;

    return (
      <>
        <Helmet>
          {this.state.faceId && this.state.commType && (
            <meta property={`fb:${this.state.commType}`} content={this.state.faceId} />
          )}
          <link rel="preconnect" href={process.env.IMAGES_CDN} />
          {hasInstagram && <link rel="preconnect" href="https://scontent.cdninstagram.com" crossOrigin />}
          {hasTwitter && <link rel="preconnect" href="https://ton.twimg.com" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://scontent.fhel1-1.fna.fbcdn.net" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://www.facebook.com" crossOrigin />}
          {hasFacebook && <link rel="preconnect" href="https://static.xx.fbcdn.net" crossOrigin />}
          <html lang={this.props.pageContext.metaLanguage} />
          {this.state.necessaryHead}
          <title>{this.state.title}</title>
          {this.state.description && <meta name="description" content={this.state.description} />}
          {this.props.href && <meta name="og:url" content={this.props.href} />}
          {this.state.description && <meta property="og:description" content={this.state.description} />}
          {this.state.metaTitle && <meta property="og:title" content={this.state.metaTitle} />}
          {this.state.previewImage && <meta property="og:image" content={this.state.previewImage} />}
          {this.props.pageContext.feeds?.length > 0 &&
            this.props.pageContext.feeds.map(({ title, url }) => (
              <link rel="alternate" type="application/rss+xml" title={title} href={url} />
            ))}
          {this.props.pageContext.hasContact &&
            typeof window !== 'undefined' &&
            typeof window.grecaptcha === 'undefined' && (
              <script
                src={`https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`}
                async
                defer
              />
            )}
          <style>{css}</style>
        </Helmet>
        <IEWarning />
        {!this.props.pageContext.navigation.styles.overlay && (
          <NavigationBar
            noInfoRow={this.props.noInfoRow}
            isScrolling={this.props.isScrolling}
            pageContext={this.props.pageContext}
          />
        )}
      </>
    );
  }
}

export default NavHead;
